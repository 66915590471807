import React, { FC } from 'react';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Button } from 'antd';
import { Link, useNavigate } from "@reach/router";
import { RightOutlined } from '@ant-design/icons';

import getPendingUsers from '../repository/pending_repository';

import { stateStr, genderStr, basePath } from '../../../common';

const { Content } = Layout;

const ListView: FC = () => {
  const { loading, items } = getPendingUsers();
  const navigate = useNavigate();

  const data = items.map((item) => {
    return {
      key: item.id,
      ...item,
      gotoId: item.id,
    }
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <Link to={basePath(`/users/${id}`)}>{id}</Link>
      )
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      render: (gender: string) => (
        <span style={{ color: genderStr(gender).color }}>{genderStr(gender).value}</span>
      )
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '메모',
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: '설정',
      dataIndex: 'gotoId',
      key: 'gotoId',
      render: (gotoId: number) => (
        <Button type="primary" danger ghost icon={<RightOutlined />} onClick={() => {
          navigate(basePath(`/users/${gotoId}`))
        }}>보러가기</Button>
      )
    },
  ];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>승인대기</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table loading={loading} dataSource={data} columns={columns} />
      </div>
    </Content>
  );
}

export default ListView;
