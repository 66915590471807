import React, { FC } from 'react';
import _ from 'lodash';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Tag, Typography, Button } from 'antd';
import { Link, useNavigate } from "@reach/router";
import { RightOutlined } from '@ant-design/icons';

import Loading from '../../../components/loading';

import { useGetMediaByRequest } from '../repository/request_media_repository';
import { basePath, stateStr } from '../../../common';

const { Content } = Layout;
const { Text } = Typography;

const ListView: FC = () => {
  const { loading, items } = useGetMediaByRequest();
  const navigate = useNavigate();

  if (loading) {
    return <Loading />
  }


  const data = items.map((item) => {
    return {
      key: item.id,
      id: item.id,
      tag: '사진요청',
      reuqest_count: item.medias?.length || 0,
      gotoId: item.id,
    }
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <Link to={basePath(`/users/${id}`)}>{id}</Link>
      )
    },
    {
      title: '상태',
      dataIndex: 'tag',
      key: 'tag',
      render: (tag: string) => (
        <Tag color={stateStr('request').color}>{tag}</Tag>
      )
    },
    {
      title: '건수',
      dataIndex: 'reuqest_count',
      key: 'reuqest_count',
      render: (reuqest_count: number) => (
        <Text>{reuqest_count} 건</Text>
      )
    },
    {
      title: '설정',
      dataIndex: 'gotoId',
      key: 'gotoId',
      render: (gotoId: number) => (
        <Button type="primary" danger ghost icon={<RightOutlined />} onClick={() => {
          navigate(basePath(`/users/${gotoId}`))
        }}>보러가기</Button>
      )
    },
  ];


  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>사진 재요청</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table dataSource={data} columns={columns} />
      </div>
    </Content>
  );
}

export default ListView;