import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import firebase from "gatsby-plugin-firebase";

import { Users, User, UserScore, StateReject } from '../../user/model/user';

export const useGetMediaByRequest = (query?: string): {
  loading: boolean,
  items: Array<User>,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Users>(
    gql`
      query GetMediaByRequest {
        items: users(where: {medias: {state: {_eq: "request"}, user: {is_delete: {_eq: false}, state: {_eq: "success"}}}}) {
          id
          medias(where: {state: {_eq: "request"}}, order_by: {updated_time: desc}) {
            id
            created_time
            message
            state
            type
            updated_time
            new_url
            index
            url
          }
        }
      }
    `, {
    fetchPolicy: "cache-and-network",
    onError: err => {
      alert(err.message);
    }
  });

  useEffect(() => {
    async function call() {
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        queryHandler({
          context: {
            headers: {
              'Authorization': `Bearer ${idToken}`
            },
          },
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }

    call()
  }, []);

  return { loading, items: data?.items || [] };
}