import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import firebase from "gatsby-plugin-firebase";

import { Users, User, UserScore, StateReject } from '../model/user';

import { GET_USERS, GET_USER_BY_ID } from './user_repository.query';

export const useGetUsers = (): {
  loading: boolean,
  items: Array<User>,
  refrash: Function,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Users>(
    GET_USERS, {
    onError: err => {
      alert(err.message);
    }
  });

  async function call(query?: any) {
    console.log(query)
    let limit = 500;
    let offset = 0;
    let where = {}

    if (query?.category != 'all') {
      if (query?.keyword) {
        limit = 20;
        offset = 0;

        if (query.category == 'nickname') {
          where = {
            "profiles": {
              "nickname": {
                "_eq": query?.keyword
              }
            }
          }
        } else if (query.category == 'id') {
          where = {
            "id": {
              "_eq": query?.keyword
            }
          }
        } else if (query.category == 'email') {
          where = {
            "email": {
              "_eq": query?.keyword
            }
          }
        } else if (query.category == 'name') {
          where = {
            "name": {
              "_like": `%${query?.keyword}`
            }
          }
        } else if (query.phone == 'phone') {
          where = {
            "phone": {
              "_eq": query?.keyword
            }
          }
        }
      }
    }

    try {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      queryHandler({
        variables: {
          "limit": limit,
          "offset": offset,
          "where": where,
        },
        context: {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    call()
  }, []);

  return { loading, items: data?.items || [], refrash: call };
}

export const useGetUserDetail = (id: number): {
  loading: boolean,
  items: Array<User>,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Users>(
    GET_USER_BY_ID, {
    variables: {
      "id": {
        "_eq": id
      }
    },
    fetchPolicy: "network-only",
    onError: err => {
      alert(err.message);
    }
  });


  useEffect(() => {
    async function call() {
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        queryHandler({
          context: {
            headers: {
              'Authorization': `Bearer ${idToken}`
            },
          },
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }

    call()
  }, []);

  return { loading, items: data?.items ?? [] };
}



export const useUpdateUser = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation UpdateUserAndScore($where: users_bool_exp!, $_set: users_set_input!) {
        update_users(where: $where, _set: $_set) {
          returning {
            is_block
            is_delete
            is_private
            memo
            state
            phone
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "where": {
          "id": {
            "_eq": user.id
          }
        },
        "_set": {
          "state": user.state,
          "is_private": user.is_private,
          "is_block": user.is_block,
          "is_delete": user.is_delete,
          "memo": user.memo,
          "phone": user.phone,
        },
      },
      update(cache, { data }) {
        const newUser = data.update_users.returning[0];
        cache.writeFragment({
          id: `users:${user.id}`,
          fragment: gql`
            fragment updateUsers on users {
              id
              is_block
              is_delete
              is_private
              memo
              state
              phone
            }
          `,
          data: {
            state: newUser.state,
            is_private: newUser.is_private,
            is_block: newUser.is_block,
            is_delete: newUser.is_delete,
            memo: newUser.memo,
            phone: newUser.phone,
          },
        });
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}

export const useUpdateUserScore = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation UpdateUserScore($_set: user_scores_set_input!, $where: user_scores_bool_exp!) {
        update_user_scores(_set: $_set, where: $where) {
          returning {
            profile_score
            private_score
            bonus_score
            verify_total_score
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User, userScore: UserScore) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "where": {
          "user_id": {
            "_eq": user.id
          }
        },
        "_set": {
          "verify_total_score": userScore.verify_total_score,
          "profile_score": userScore.profile_score,
          "private_score": userScore.private_score,
          "bonus_score": userScore.bonus_score
        }
      },
      update(cache, { data }) {
        console.log(data.update_user_scores.returning);
        cache.writeFragment({
          id: `users:${user.id}`,
          fragment: gql`
            fragment updateUsers on users {
              id
              user_scores {
                profile_score
                private_score
                bonus_score
                verify_total_score
              }
            }
          `,
          data: {
            user_scores: data.update_user_scores.returning
          },
        });
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}

export const useInsertRejectState = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation insertRejectState($objects: [state_rejects_insert_input!]!){
        insert_state_rejects(objects: $objects) {
          affected_rows
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User, stateReject: StateReject) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        objects: [
          {
            user_id: user.id,
            is_verify: stateReject.is_verify,
            is_media: stateReject.is_media,
            is_profile: stateReject.is_profile,
            message: stateReject.message,
          }
        ]
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}