import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import firebase from "gatsby-plugin-firebase";
import { Button, Radio, Row, Col, Space, Typography, Modal, Input, Select, Divider, Checkbox, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { User, UserScore, StateReject } from '../model/user';
import { verifyData, STATE_TYPE } from '../../../common';

import { useUpdateUser, useUpdateUserScore, useInsertRejectState } from '../repository/user_repository';

const { Text } = Typography;
const { confirm } = Modal;
const { TextArea } = Input;
const { Option } = Select;

const UserReview: FC<{
  user: User
}> = ({ user }) => {
  if (user.is_delete) {
    return <div></div>;
  }

  let isPendingVerify = false
  let isPendingMedia = false
  let isRejectVerify = false
  let isRejectMedia = false


  const pendingVerifyIndex = _.findIndex(user.verifies, function (o) { return o.state == 'pending' })
  if (pendingVerifyIndex != -1) {
    isPendingVerify = true
  }

  const pendingMediaIndex = _.findIndex(user.medias, function (o) { return o.state == 'pending' })
  if (pendingMediaIndex != -1) {
    isPendingMedia = true
  }

  const approveTotal = _.reduce(_.filter(user.verifies, function (o) { return o.state == 'approve' }), function (sum, n) {
    const symbolData = verifyData(n.symbol)
    return sum + symbolData.score;
  }, 0);

  // 인증 총점이 1점 이상이면 승인가능
  // if (user.gender == 'male' && approveTotal < 1) {
  //   isRejectVerify = true
  // }

  // 대표, 필수 사진이 reject인 경우 승인버튼 disabled
  const rejectMediaIndex = _.findIndex(user.medias, function (o) { return o.state == 'reject' && _.includes([0, 1, 2], o.index) })
  if (rejectMediaIndex != -1) {
    isRejectMedia = true
  }


  const isApprove = isPendingVerify || isPendingMedia || isRejectMedia

  return (
    <Row justify="center" align="middle">
      <Space>
        <UserApprove user={user} isApprove={isApprove} verifyTotalScore={approveTotal} />
        <UserReject user={user}></UserReject>
      </Space>
    </Row>
  );
}

export default UserReview;

const UserApprove: FC<{ user: User, isApprove: boolean, verifyTotalScore: number }> = ({ user, isApprove, verifyTotalScore }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [privateScore, setPrivateScore] = useState<number>(user.user_scores[0].private_score ?? 0);

  const [updateUser, isLoading] = useUpdateUser();
  const [updateUserScore, isLoading2] = useUpdateUserScore();

  const handleOk = async () => {
    setLoading(true);
    try {
      const newUser = _.cloneDeep<User>(user);

      let bonusScore: number = 0;

      const newUserScore: UserScore = {
        "bonus_score": 0,
        "verify_total_score": verifyTotalScore,
        "profile_score": 0,
        "private_score": privateScore,
      };

      newUser.state = STATE_TYPE.APPROVE;

      await updateUser(newUser);
      await updateUserScore(newUser, newUserScore);
      message.success('승인이 완료 되었습니다.');

      var sendNotificationMessage = firebase.app().functions('asia-northeast1').httpsCallable('sendNotificationMessage')
      const result = await sendNotificationMessage({
        uid: user.uid,
        type: 'approve'
      })

      if (result.data == 'success') {
        message.info('알림을 보냈습니다.');
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  }

  const handleCancel = (e: any) => {
    setShowModal(false);
  };

  if (user.state == STATE_TYPE.PENDING) {
    return (
      <>
        <Button size="large" type="primary" loading={loading} disabled={isApprove} onClick={() => setShowModal(true)}>승인</Button>
        <Modal
          okText="승인"
          cancelText="취소"
          key={user.id}
          title="사용자 승인"
          visible={showModal}
          confirmLoading={loading}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Row justify="center" align="middle">
            <Space direction="vertical" style={{ textAlign: 'center' }} >
              <Text type="danger">* 내부평가 점수를 선택해주세요.(매칭 우선순위에 적용됩니다)</Text>
              <Radio.Group onChange={(v) => setPrivateScore(parseInt(v.target.value))} value={privateScore}>
                <Radio value={0}>0</Radio>
                <Radio value={1}>1</Radio>
                <Radio value={2}>2</Radio>
                <Radio value={3}>3</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={5}>5</Radio>
              </Radio.Group>
              <Text type="danger">* 등급점수에 포함되지 않습니다.</Text>
            </Space>
          </Row>
        </Modal>
      </>
    );
  } else {
    return (
      <Button size="large" type="primary" disabled >승인</Button>
    );
  }
}


const rejectUserMessage = [
  {
    value: 0,
    label: "내용없음",
  },
  {
    value: 1,
    label: "내부 심사기준에 부합되지 않아 가입이 불가합니다.\n양해 부탁드립니다.",
  },
  {
    value: 2,
    label: "필요서류를 재검토 후 다시 이용 부탁드립니다.",
  },
  {
    value: 3,
    label: "프로필부분을 수정해주세요.",
  },
  {
    value: 4,
    label: "프로필 사진 심사에서 통과하지 못했습니다.",
  },
  {
    value: 5,
    label: "직접입력",
  },
]

let INIT_REJECTS = {
  profile: {
    name: '프로필',
    checked: false,
    disabled: false,
  },
  verify: {
    name: '인증',
    checked: false,
    disabled: false,
  },
  media: {
    name: '사진',
    checked: false,
    disabled: false,
  }
}

const UserReject: FC<{ user: User }> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [type, setType] = useState<number>(0);
  const [isNotification, setIsNotification] = useState<boolean>(false);
  const [checkReject, setCheckReject] = useState(INIT_REJECTS)

  const [updateUser, isLoading] = useUpdateUser();
  const [insertStateReject, isLoading2] = useInsertRejectState();

  const getMessage = (): string => {
    let value = _.find(rejectUserMessage, (data) => {
      return data.value == type;
    })

    if (value) {
      if (value.value == 0) {
        return '';
      } else if (value.value == rejectUserMessage[rejectUserMessage.length - 1].value) {
        return rejectMessage;
      } else {
        return value.label;
      }
    }
    return rejectMessage;
  }

  const handleOk = async (e: any) => {
    setLoading(true);
    try {
      const newUser: User = _.cloneDeep(user);
      newUser.state = STATE_TYPE.REJECT;

      const newStateReject: StateReject = {
        is_media: checkReject.media.checked,
        is_profile: checkReject.profile.checked,
        is_verify: checkReject.verify.checked,
        message: getMessage(),
      };

      await updateUser(newUser);
      await insertStateReject(user, newStateReject);
      message.success('거부가 완료 되었습니다.');

      if (isNotification) {
        var sendNotificationMessage = firebase.app().functions('asia-northeast1').httpsCallable('sendNotificationMessage')
        const result = await sendNotificationMessage({
          uid: user.uid,
          type: 'reject'
        })

        if (result.data == 'success') {
          message.info('알림을 보냈습니다.');
        }
      }

      setShowModal(false);
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const handleCancel = (e: any) => {
    setShowModal(false);
  };

  const onChangeCheck = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    let newCheckReject = _.cloneDeep(checkReject);
    newCheckReject[name].checked = checked;
    setCheckReject(newCheckReject);
  }

  useEffect(() => {
    if (showModal) {
      const rejectVerify = _.findIndex(user.verifies, function (o) { return o.state == 'reject' }) !== -1;
      const rejectMedia = _.findIndex(user.medias, function (o) { return o.state == 'reject' }) !== -1;

      setType(0)
      setRejectMessage('')

      let newCheckReject = _.cloneDeep(checkReject);
      newCheckReject['verify'].checked = rejectVerify;
      newCheckReject['verify'].disabled = rejectVerify;

      newCheckReject['media'].checked = rejectMedia;
      newCheckReject['media'].disabled = rejectMedia;

      setCheckReject(newCheckReject);
    }
  }, [showModal])

  return (
    <>
      <Button size="large" type="primary" danger loading={loading} onClick={() => setShowModal(true)}>거부</Button>
      <Modal
        key={user.id}
        title="거부 사유"
        okText="거부"
        cancelText="취소"
        visible={showModal}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Space>
          <>
            {_.map(checkReject, (item, key) => <Checkbox name={key} key={key} checked={item.checked} disabled={item.disabled} onChange={onChangeCheck}>{item.name}</Checkbox>)}
          </>
        </Space>
        <Divider />
        <Select value={type} onChange={(value) => setType(value)} style={{ width: 300 }}>
          {rejectUserMessage.map(item => {
            return (
              <Option key={`${item.value}`} value={item.value}>{item.label}</Option>
            )
          })}
        </Select>
        {type == rejectUserMessage.length - 1 && (
          <>
            <Divider />
            <p className="has-text-weight-bold" style={{ marginBottom: 10 }}>반려 사유를 적어 주세요.</p>
            <TextArea rows={4}
              value={rejectMessage}
              onChange={(e) => setRejectMessage(e.currentTarget.value)}
            />
          </>
        )}
        <Divider />
        <Checkbox onChange={(e) => setIsNotification(e.target.checked)} checked={isNotification}>알림 보내기</Checkbox>
      </Modal>
    </>
  )
}