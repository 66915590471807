import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useState } from 'react';

import firebase from "gatsby-plugin-firebase";

import { User, Media } from '../model/user';

export const useUpdateMedia = (): [Function, boolean] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation updateMedia($userId: Int!, $id: Int!, $_set: medias_set_input) {
        update_medias(where: {user_id: {_eq: $userId}, id: {_eq: $id}}, _set: $_set) {
          affected_rows
          returning {
            id
            state
            index
            url
            new_url
            message
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User, media: Media) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "userId": user.id,
        "id": media.id,
        "_set": {
          "state": media.state,
          "index": media.index,
          "url": media.url,
          "new_url": media.new_url,
          "message": media.message,
        }
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, loading];
}


export const useDeleteMedia = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation MyMutation($where: medias_bool_exp!) {
        delete_medias(where: $where) {
          __typename
          affected_rows
          returning {
            id
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User, media: Media) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "where": {
          "id": {
            "_eq": media.id
          },
          "user_id": {
            "_eq": user.id
          }
        }
      },
      update(cache, { data }) {
        const updateUser = cache.readFragment<User>({
          id: `users:${user.id}`,
          fragment: gql`
            fragment UpdateUsers on users {
              id
              medias(order_by: {index: asc}) {
                id
                index
                url
                type
                state
                new_url
                message
                updated_time
              }
            }
          `,
        });

        cache.writeFragment({
          id: `users:${user.id}`,
          fragment: gql`
            fragment UpdateUsers on users {
              id
              medias(order_by: {index: asc}) {
                id
                index
                url
                type
                state
                new_url
                message
                updated_time
              }
            }
          `,
          data: {
            medias: user.medias.filter((t) => t.id !== media.id)
          },
        });
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}