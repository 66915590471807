import React, { FC } from 'react';
import { Card, Descriptions } from 'antd';

import { User } from '../model/user';

const UserProfile: FC<{ user: User }> = ({ user }) => {
  const profile = user.profiles[0]
  if (profile == null) {
    return <div></div>
  }

  return (
    <Card type="inner" title="프로필 정보">
      <Descriptions
        bordered
        size="small"
      >
        <Descriptions.Item label='닉네임'>
          {profile.nickname || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='직업'>
          {profile.job || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='지역'>
          {profile.region || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='키'>
          {profile.height || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='형액형'>
          {profile.blood_type || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='체형'>
          {profile.body_type || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='학력'>
          {profile.education || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='종교'>
          {profile.religion || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='흡연'>
          {profile.smoking || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='술'>
          {profile.drinking || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='취미'>
          {profile.hobbies && profile.hobbies.length > 0 && profile.hobbies.map((hobby, key) => {
            return (
              <span key={key} style={{ margin: 3 }}>
                #{hobby}
              </span>
            )
          }) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='이상형'>
          {profile.characters && profile.characters.map((character, key) => {
            return (
              <span key={key} style={{ margin: 3 }}>
                #{character}
              </span>
            )
          }) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='소개'>
          <div style={{ whiteSpace: 'pre-line', maxWidth: 350 }}>{profile.introduction || '-'}</div>
        </Descriptions.Item>
        <Descriptions.Item label='인터뷰'>
          {profile.interviews && profile.interviews.length > 0 && profile.interviews.map((interview, key) => {
            return (
              <div key={key} style={{ whiteSpace: 'pre-line', maxWidth: 350 }}>
                {key + 1}. {interview || '-'}
              </div>
            )
          }) || '-'}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default UserProfile;