import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';

import firebase from "gatsby-plugin-firebase";

import { Users, User, Verify } from '../model/user';

import { arraryToVarchar } from '../../../common';

export const useUpdateVerify = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation updateVerifyBySymbol($userId: Int!, $id: Int!, $_set: verifies_set_input) {
        update_verifies(where: {user_id: {_eq: $userId}, id: {_eq: $id}}, _set: $_set) {
          affected_rows
          returning {
            id
            message
            new_urls
            state
            symbol
            type
            urls
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User, verify: Verify) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "userId": user.id,
        "id": verify.id,
        "_set": {
          "type": verify.type,
          "symbol": verify.symbol,
          "state": verify.state,
          "urls": arraryToVarchar(verify.urls),
          "new_urls": arraryToVarchar(verify.new_urls),
          "message": verify.message,
          "updated_time": "now()"
        }
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}


export const useDeleteVerify = (): [Function, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [mutationHandler] = useMutation(
    gql`
      mutation MyMutation($where: verifies_bool_exp!) {
        delete_verifies(where: $where) {
          affected_rows
          returning {
            id
          }
        }
      }
    `, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: err => {
      setLoading(false);
      throw err.message;
    }
  });

  async function call(user: User, verify: Verify) {
    setLoading(true);
    const idToken = await firebase.auth().currentUser?.getIdToken();
    mutationHandler({
      variables: {
        "where": {
          "id": {
            "_eq": verify.id
          },
          "user_id": {
            "_eq": user.id
          }
        }
      },
      update(cache, { data }) {
        const updateUser = cache.readFragment<User>({
          id: `users:${user.id}`,
          fragment: gql`
            fragment updateUsers on users {
              id
              verifies(order_by: {type: asc}) {
                id
                type
                symbol
                urls
                new_urls
                state
                message
                updated_time
              }
            }
          `,
        });

        cache.writeFragment({
          id: `users:${user.id}`,
          fragment: gql`
            fragment updateUsers on users {
              id
              verifies(order_by: {type: asc}) {
                id
                type
                symbol
                urls
                new_urls
                state
                message
                updated_time
              }
            }
          `,
          data: {
            verifies: user.verifies.filter((t) => t.id !== verify.id)
          },
        });
      },
      context: {
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
      },
    });
  }

  return [call, isLoading];
}