import React, { FC } from 'react';
import { format } from 'date-fns'
import { Link, useParams } from '@reach/router';
import { Breadcrumb, Layout, Tag, Card, Button, Divider, Row, Col } from 'antd';
const { Content } = Layout;

import { useGetUserDetail } from '../repository/user_repository';
import Loading from '../../../components/loading';
import UserInfo from '../component/user_info';
import UserProfile from '../component/user_profile';
import UserVerify from '../component/user_verify';
import UserMedia from '../component/user_media';
import UserScore from '../component/user_score';
import UserReview from '../component/user_review';

import './user_detail_screen.less';
import { basePath } from '../../../common';

const IndexPage: FC = () => {
  const params = useParams()
  const { loading, items } = useGetUserDetail(params.userId);
  if (items.length < 1 || loading) {
    return <Loading />
  }

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to={basePath('/users')}>사용자</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{params.userId}</Breadcrumb.Item>
      </Breadcrumb>
      <UserInfo user={items[0]} />
      <Divider />
      <UserProfile user={items[0]} />
      <Divider />
      <UserScore user={items[0]} />
      <Divider />
      <UserVerify user={items[0]} />
      <Divider />
      <UserMedia user={items[0]} />
      <Divider />
      <UserReview user={items[0]} />
    </Content>
  );
}

export default IndexPage;

