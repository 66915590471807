import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  PullRequestOutlined,
  FileImageOutlined,
  BarsOutlined,
} from '@ant-design/icons';

import { useNavigate, Link } from "@reach/router"

import './layout.less';

import { signOut } from '../../features/signin/repository/signin_repository';

import { basePath } from '../../common';

const { Header, Content, Footer, Sider, } = Layout;
const { SubMenu } = Menu;

const LayoutComponent: React.FC = ({ children }) => {
  const navigate = useNavigate()

  const [collapsed, setCollapsed] = useState(false);

  const onClickLogout = () => {
    if (confirm('로그아웃을 하시겠습니까?')) {
      signOut();
    }
  }

  function getSelectKeys(): Array<string> {
    let keys = ['1'];
    console.log(location.pathname)

    if (location.pathname.split('/')[2]) {
      const path = location.pathname.split('/')[2];
      switch (path) {
        case 'users':
          keys = ['2'];
          break;
        case 'pending':
          keys = ['3'];
          break;
        case 'request_verify':
          keys = ['4'];
          break;
        case 'request_media':
          keys = ['5'];
          break;
      }
    }


    return keys;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" style={{
          height: 32, margin: 16,
          background: 'rgba(255, 255, 255, 0.3)'
        }}>
          <div style={{
            color: '#fff', fontWeight: 'bold', textAlign: 'center',
            paddingTop: 5
          }}>{collapsed ? '하이소' : '하이소사이어티 관리자'}</div>
        </div>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" selectedKeys={getSelectKeys()}>
          <Menu.Item key="1" icon={<DesktopOutlined />} onClick={() => navigate(basePath())}>
            대시보드
            </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />} onClick={() => navigate(basePath('/users'))}>
            사용자
            </Menu.Item>
          <Menu.Item key="3" icon={<TeamOutlined />} onClick={() => navigate(basePath('/pending'))}>
            승인대기
          </Menu.Item>
          <Menu.Item key="4" icon={<PullRequestOutlined />} onClick={() => navigate(basePath('/request_verify'))}>
            인증 재요청
          </Menu.Item>
          <Menu.Item key="5" icon={<FileImageOutlined />} onClick={() => navigate(basePath('/request_media'))}>
            사진 재요청
          </Menu.Item>
          <Menu.Item key="6" icon={<LogoutOutlined />} onClick={onClickLogout}>
            로그아웃
            </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        {children}
        <Footer style={{ textAlign: 'center' }}>©2020 Created by Nuca, Inc</Footer>
      </Layout>
    </Layout >
  )
};

export default LayoutComponent;