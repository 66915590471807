import React, { FC, useState } from 'react';
import _ from 'lodash';
import { format } from 'date-fns'
import firebase from "gatsby-plugin-firebase";
import { Tag, Card, Button, Space, Descriptions, Input, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { stateStr, genderStr } from '../../../common';
import { User } from '../model/user';
import { useUpdateUser } from '../repository/user_repository';

const { TextArea } = Input;
const { confirm } = Modal;

const UserInfo: FC<{ user: User }> = ({ user }) => {
  const today = new Date();
  const brithday = new Date(user.birthday);

  return (
    <Card type="inner" title="사용자 정보" extra={
      <Space>
        <UserPrivate user={user} />
        <UserDelete user={user} />
      </Space>
    }>
      <Descriptions
        bordered
        size="small"
      >
        <Descriptions.Item label='UID'>
          {user.uid}
        </Descriptions.Item>
        <Descriptions.Item label='이메일'>
          {user.email}
        </Descriptions.Item>
        <Descriptions.Item label='폰번호'>
          {user.phone}
        </Descriptions.Item>
        <Descriptions.Item label='이름'>
          {user.name}
        </Descriptions.Item>
        <Descriptions.Item label='성별'>
          <span style={{ color: genderStr(user.gender).color }}>{genderStr(user.gender).value}</span>
        </Descriptions.Item>
        <Descriptions.Item label='생일 / 나이'>
          {user.birthday} / {today.getFullYear() - brithday.getFullYear() + 1}세
        </Descriptions.Item>

        <Descriptions.Item label='상태'>
          <Tag style={{ lineHeight: 'inherit' }} color={stateStr(user.state).color} key={stateStr(user.state).value}>
            {stateStr(user.state).value}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label='업데이트'>
          <span>{format(new Date(user.updated_time), 'yyyy-MM-dd HH:mm')}</span>
        </Descriptions.Item>
        <Descriptions.Item label='생성 날짜'>
          <span>{format(new Date(user.created_time), 'yyyy-MM-dd HH:mm')}</span>
        </Descriptions.Item>
        <Descriptions.Item label='다이아'>
          <div>{user.gems[0]?.amount ?? 0}</div>
        </Descriptions.Item>
        <Descriptions.Item label='메모'>
          <UserMemo user={user} />
        </Descriptions.Item>
      </Descriptions>
    </Card >
  );
}

export default UserInfo;

const UserPrivate: FC<{ user: User }> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [updateUser, isLoading] = useUpdateUser();

  const onClick = async () => {
    confirm({
      title: user.is_private ? '공개로 전환 할까요?' : '비공개로 전환 할까요?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        setLoading(true);
        try {
          const newUser = _.cloneDeep(user);
          newUser.is_private = !user.is_private;

          await updateUser(newUser);

          message.success('완료 되었습니다.');
        } catch (err) {
          message.error(err.message);
        }
        setLoading(false);
      },
      onCancel() { },
    });
  }

  return (
    <Button type="primary" loading={loading} ghost={!user.is_private} onClick={onClick}>{user.is_private ? '비공개' : '공개'}</Button>
  )
}

const UserDelete: FC<{ user: User }> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [updateUser, isLoading] = useUpdateUser();

  const onClick = async () => {
    if (!user.is_delete) {
      confirm({
        title: '탈퇴를 진행 할까요?',
        icon: <ExclamationCircleOutlined />,
        content: '탈퇴 시 복구가 불가능합니다.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          setLoading(true);

          try {
            const sendWithdrawal = firebase.app().functions('asia-northeast1').httpsCallable('sendWithdrawal');
            // firebase.app().functions().useFunctionsEmulator('http://localhost:500')
            // var sendWithdrawal = firebase.functions().httpsCallable('sendWithdrawal')

            const result = await sendWithdrawal({
              uid: user.uid,
            })
            console.log(result)

            const newUser = _.cloneDeep(user);
            newUser.is_block = true;
            newUser.is_private = true;
            newUser.is_delete = true;
            newUser.phone = `x${user.phone}`;

            await updateUser(newUser);

            message.success('탈퇴가 완료 되었습니다.');
          } catch (err) {
            message.error(err.message);
          }

          setLoading(false);
        },
        onCancel() { },
      });
    }
  }

  return (
    <Button type="primary" danger loading={loading} ghost={!user.is_delete} onClick={onClick}>{user.is_delete ? '탈퇴됨' : '탈퇴하기'}</Button>
  )
}



const UserMemo: FC<{ user: User }> = ({ user }) => {
  const [memo, setMemo] = useState<string>(user.memo ?? '');

  const [updateUser, isLoading] = useUpdateUser();

  const onClick = async () => {
    try {
      let newUser = _.cloneDeep(user)
      newUser.memo = memo.trim();
      await updateUser(newUser);

      message.success('메모가 적용 되었습니다.');
    } catch (err) {
      message.error(err.message)
    }
  }

  return (
    <>
      <TextArea rows={2}
        value={memo}
        onChange={(e) => setMemo(e.currentTarget.value)}
      />
      <Button block onClick={onClick} loading={isLoading}>적용</Button>
    </>
  )
}
