import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Empty, Card, Descriptions, Tooltip, Select, Typography, message } from 'antd';

import { stateStr, genderStr, STATE_TYPE } from '../../../common';
import { User, UserScore } from '../model/user';

import { useUpdateUserScore } from '../repository/user_repository';

const { Option } = Select;
const { Text } = Typography;

const UserScoreComponent: FC<{ user: User }> = ({ user }) => {
  console.log(user);
  if (user.user_scores?.length < 1) {
    return (
      <Card type="inner" title="사용자 점수">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Card>
    )
  }

  const item = user.user_scores[0];
  const totalScore = (item.verify_total_score + item.bonus_score + item.profile_score);
  let grade: string;
  let tooltipTitle: string;

  // 내부점수는 등급에 포함 안함.
  if ((totalScore) >= 10) {
    grade = '하이소사이어티';
    tooltipTitle = '10이상: 하이소사이어티';
  } else if ((totalScore) >= 7) {
    grade = '다이아몬드'
    tooltipTitle = '7~9: 다이아몬드';
  } else if ((totalScore) >= 4) {
    grade = '플래티넘'
    tooltipTitle = '4~6: 플래티넘';
  } else if ((totalScore) >= 1) {
    grade = '골드'
    tooltipTitle = '1~3: 골드';
  } else {
    grade = '일반'
    tooltipTitle = '0: 일반';
  }

  return (
    <Card type="inner" title={<div><span>사용자 점수: </span><Tooltip placement="top" title={tooltipTitle}>{grade}</Tooltip></div>}>
      <Descriptions
        column={{ xs: 6, sm: 6, md: 6 }}
        bordered
        layout="vertical"
        size="small">
        <Descriptions.Item label='내부' style={{ textAlign: 'center' }}>
          <PrivateScore user={user} />
        </Descriptions.Item>
        <Descriptions.Item label='전체' style={{ textAlign: 'center' }}>
          <Text>{totalScore}</Text>
        </Descriptions.Item>
        <Descriptions.Item label='인증' style={{ textAlign: 'center' }}>
          <Text>{item.verify_total_score}</Text>
        </Descriptions.Item>
        <Descriptions.Item label='프로필' style={{ textAlign: 'center' }}>
          <Text>{item.profile_score}</Text>
        </Descriptions.Item>
        <Descriptions.Item label='보너스' style={{ textAlign: 'center' }}>
          <Text>{item.bonus_score}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

export default UserScoreComponent;

const PrivateScore: FC<{ user: User }> = ({ user }) => {
  const item = user.user_scores[0];

  const [privateScore, setPrivateScroe] = useState(item.private_score);
  const [updateUserScore, loading] = useUpdateUserScore();

  const onChange = async (value: number) => {
    try {
      setPrivateScroe(value);
      const newUserScore: UserScore = {
        "private_score": value,
        "bonus_score": item.bonus_score,
        "verify_total_score": item.verify_total_score,
        "profile_score": item.profile_score,
      };
      await updateUserScore(user, newUserScore);

      message.success('내부 점수가 수정되었습니다.');
    } catch (err) {
      message.error(err.message);
    }
  }


  if (user.state == STATE_TYPE.PENDING) {
    return <Text>{privateScore}</Text>
  } else {
    return (
      <Select value={privateScore} onChange={onChange}>
        <Option value={0}>0</Option>
        <Option value={1}>1</Option>
        <Option value={2}>2</Option>
        <Option value={3}>3</Option>
        <Option value={4}>4</Option>
        <Option value={5}>5</Option>
      </Select>
    );
  }
}