export enum STATE_TYPE {
  REQUEST = 'request',
  PENDING = 'pending',
  APPROVE = 'approve',
  SUCCESS = 'success',
  REJECT = 'reject',
}

export const basePath = function (path?: string) {
  return `/console${path || ''}`;
}

export const stateStr = function (index: string) {
  if (index == null) {
    return {
      value: '가입',
      color: 'default',
    }
  }

  const obj = {
    'request': {
      value: '요청',
      color: 'gold',
    },
    'pending': {
      value: '대기',
      color: 'orange',
    },
    'approve': {
      value: '승인',
      color: 'processing',
    },
    'success': {
      value: '성공',
      color: 'success',
    },
    'reject': {
      value: '거부',
      color: 'error',
    },
    'is_delete': {
      value: '삭제',
      color: '#f00',
    },
    'is_block': {
      value: '차단',
      color: '#222',
    },
    'is_private': {
      value: '비공개',
      color: '#f50',
    },
  }

  if (obj[index] == undefined) return {
    value: '추적 사용자',
    color: 'error',
  }

  return obj[index]
}

export const genderStr = function (index: string) {
  const obj = {
    'female': {
      value: '여성',
      color: '#ff3860',
    },
    'male': {
      value: '남성',
      color: '#3273dc'
    }
  }

  return obj[index]
}

export const arraryToVarchar = function (arr: Array<any>) {
  if (arr === null) return null;

  var str = arr.reduce((acc, value, index) => {
    if (index == 0) {
      if (value) {
        return acc + value

      } else {
        return acc + `""`
      }

    } else {
      if (value) {
        return acc + `,${value}`

      } else {
        return acc + `,""`
      }
    }
  }, "")

  return `{${str}}`
}

export const symbol = {
  'car_1': {
    value: 'car_1',
    type: 'car',
    typeStr: '자동차',
    title: '고급 자동차',
    contents: '6천만원 ~ 1억원 차량',
    score: 1,
  },
  'car_2': {
    value: 'car_2',
    type: 'car',
    typeStr: '자동차',
    title: '럭셔리카',
    contents: '1억원 ~ 1억 5천만원 차량',
    score: 2,
  },
  'car_3': {
    value: 'car_3',
    type: 'car',
    typeStr: '자동차',
    title: '슈퍼카',
    contents: '1억 5천만원 이상 차량',
    score: 3,
  },
  'house_1': {
    value: 'house_1',
    type: 'house',
    typeStr: '아파트',
    title: '고급 아파트',
    contents: '10억원 ~ 15억원 아파트',
    score: 1,
  },
  'house_2': {
    value: 'house_2',
    type: 'house',
    typeStr: '아파트',
    title: '럭셔리 아파트',
    contents: '15억원 ~ 30억원 아파트',
    score: 2,
  },
  'house_3': {
    value: 'house_3',
    type: 'house',
    typeStr: '아파트',
    title: '초호화 아파트',
    contents: '30억원 이상 아파트',
    score: 3,
  },
  'assets_1': {
    value: 'assets_1',
    type: 'assets',
    typeStr: '자산',
    title: '고액자산',
    contents: '5억원 ~ 20억원 개인자산',
    score: 1,
  },
  'assets_2': {
    value: 'assets_2',
    type: 'assets',
    typeStr: '자산',
    title: '초고액 자산',
    contents: '20억원 ~ 100억원 개인자산',
    score: 2,
  },
  'assets_3': {
    value: 'assets_3',
    type: 'assets',
    typeStr: '자산',
    title: '빌리어네어',
    contents: '100억원 이상 개인자산',
    score: 3,
  },
  'income_1': {
    value: 'income_1',
    type: 'income',
    typeStr: '소득',
    title: '고액소득',
    contents: '7천만원 ~ 1억원 연소득',
    score: 1,
  },
  'income_2': {
    value: 'income_2',
    type: 'income',
    typeStr: '소득',
    title: '억대연봉',
    contents: '1억원 ~ 10억원 연소득',
    score: 2,
  },
  'income_3': {
    value: 'income_3',
    type: 'income',
    typeStr: '소득',
    title: '초고액 소득',
    contents: '10억원 이상 연소득',
    score: 3,
  },
  'school_1': {
    value: 'school_1',
    type: 'school',
    typeStr: '학교',
    title: '명문대학교',
    contents:
      '서울대, 연세대, 고려대, 한양대,\n성균관대, 중앙대, 서강대, 이화여대,\n서울시립대, 서울교대, 경희대, 한국외대,\n건국대, 홍익대, 동국대, 숭실대, 숙명여대,\n카이스트, 경찰대, 사관학교, 한예종,\n특수대학교, 외국 명문대학교 등',
    score: 1,
  },
  'school_2': {
    value: 'school_2',
    type: 'school',
    typeStr: '학교',
    title: '고학력자',
    contents:
      '서울대, 연세대, 고려대, 한양대,\n성균관대, 중앙대, 서강대, 이화여대,\n서울시립대, 서울교대, 경희대, 한국외대,\n건국대, 홍익대, 동국대, 숭실대, 숙명여대,\n카이스트, 경찰대, 사관학교, 한예종,\n특수대학교, 외국 명문대학교 등 박사 이상',
    score: 2,
  },
  'house_assets_1': {
    value: 'house_assets_1',
    type: 'house_assets',
    typeStr: '자산',
    title: '집안자산',
    contents: '30억원 이상 집안자산',
    score: 2,
  },
  'family': {
    value: 'family',
    type: 'family',
    typeStr: '집안',
    title: '명문집안',
    contents: '부모님이 3급이상 공무원, 대기업 임원,\n명문대 교수, 의료계 또는 법조계 종사자',
    score: 2,
  },
  'business_1': {
    value: 'business_1',
    type: 'job',
    typeStr: '직업',
    title: '사업가',
    contents: '10억원 ~ 50억원 매출',
    score: 1,
  },
  'business_2': {
    value: 'business_2',
    type: 'job',
    typeStr: '직업',
    title: '기업가',
    contents: '50억원 이상 연매출',
    score: 2,
  },
  'expert_1': {
    value: 'expert_1',
    type: 'job',
    typeStr: '직업',
    title: '전문직',
    contents:
      '변호사, 변리사, 회계사, 관세사, 감평사,\n세무사, 노무사, 법무사, 의사, 건축사,\n판사, 기술사, 한의사, 치과의사, 계리사,\n도선사, 검사 등',
    score: 2,
  },
  'high_official': {
    value: 'high_official',
    type: 'job',
    typeStr: '직업',
    title: '고급 공무원',
    contents: '5급 이상 공무원',
    score: 2,
  },
  'major_company_1': {
    value: 'major_company_1',
    type: 'job',
    typeStr: '직업',
    title: '대기업',
    contents: '한국 100대 대기업\n또는 대형 글로벌 업체 근무자',
    score: 1,
  },
  'flight_attendant': {
    value: 'flight_attendant',
    type: 'job',
    typeStr: '직업',
    title: '승무원',
    contents: '승무원',
    score: 1,
  },
  'announcer': {
    value: 'announcer',
    type: 'job',
    typeStr: '직업',
    title: '아나운서',
    contents: '아나운서',
    score: 1,
  },
  'instructor': {
    value: 'instructor',
    type: 'job',
    typeStr: '직업',
    title: '필라테스 강사',
    contents: '강사',
    score: 1,
  },
  'teacher': {
    value: 'teacher',
    type: 'job',
    typeStr: '직업',
    title: '선생님',
    contents: '선생님',
    score: 1,
  },
  'celebrity_f': {
    value: 'celebrity_f',
    type: 'job',
    typeStr: '직업',
    title: '여자 유명인',
    contents: '배우, 모델 등 유명인',
    score: 1,
  },
  'celebrity_m': {
    value: 'celebrity_m',
    type: 'job',
    typeStr: '직업',
    title: '남자 유명인',
    contents: '배우, 모델 등 유명인',
    score: 1,
  },
  'face_m': {
    value: 'face_m',
    type: 'face_m',
    typeStr: '열굴',
    title: '얼굴',
    contents: '하이소사이어티 미남',
    score: 1,
  },
  'face_f': {
    value: 'face_f',
    type: 'face_f',
    typeStr: '열굴',
    title: '얼굴',
    contents: '하이소사이어티 미녀',
    score: 1,
  },
  'none': {
    value: 'none',
    type: 'none',
    title: '잘못된 증명',
    contents: '잘못된 증명',
    score: 0,
  }
}

export const verifyData = function (index) {
  const obj = {
    ...symbol,
  }

  if (obj[index] == undefined) return obj['none']

  return obj[index]
}


export const typeSymbol = {
  'car': {
    'car_1': symbol['car_1'],
    'car_2': symbol['car_2'],
    'car_3': symbol['car_3'],
  },
  'house': {
    'house_1': symbol['house_1'],
    'house_2': symbol['house_2'],
    'house_3': symbol['house_3'],
  },
  'assets': {
    'assets_1': symbol['assets_1'],
    'assets_2': symbol['assets_2'],
    'assets_3': symbol['assets_3'],
  },
  'income': {
    'income_1': symbol['income_1'],
    'income_2': symbol['income_2'],
    'income_3': symbol['income_3'],
  },
  'school': {
    'school_1': symbol['school_1'],
    'school_2': symbol['school_2'],
  },
  'house_assets': {
    'house_assets_1': symbol['house_assets_1'],
  },
  'family': {
    'family': symbol['family'],
  },
  'job': {
    'business_1': symbol['business_1'],
    'business_2': symbol['business_2'],
    'expert_1': symbol['expert_1'],
    'high_official': symbol['high_official'],
    'major_company_1': symbol['major_company_1'],
    'flight_attendant': symbol['flight_attendant'],
    'announcer': symbol['announcer'],
    'instructor': symbol['instructor'],
    'teacher': symbol['teacher'],
    'celebrity_f': symbol['celebrity_f'],
    'celebrity_m': symbol['celebrity_m'],
  },
  'face': {
    'face_m': symbol['face_m'],
    'face_f': symbol['face_f'],
  },
}