import React from 'react';
import { Row, Spin } from 'antd';

const LoadingComponent: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Spin size="large" />
    </Row>
  )
};

export default LoadingComponent;
