import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';

import firebase from "gatsby-plugin-firebase";

import { Users, User } from '../../user/model/user';

export const useGetUserByPending = (query?: string): {
  loading: boolean,
  items: Array<User>,
} => {
  const [queryHandler, { loading, data }] = useLazyQuery<Users>(
    gql`
      query GetUsersByPending {
        items: users(where: {state: {_eq: "pending"}}, order_by: {updated_time: desc}) {
          id
          uid
          email
          name
          phone
          gender
          memo
          grade
          state
          is_private
          is_block
          is_delete
          invite_code
          created_time
        }
      }
    `, {
    fetchPolicy: "cache-and-network",
    onError: err => {
      alert(err.message);
    }
  });

  useEffect(() => {
    async function call() {
      try {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        queryHandler({
          context: {
            headers: {
              'Authorization': `Bearer ${idToken}`
            },
          },
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }

    call()
  }, []);

  return { loading, items: data?.items || [] };
}

export default useGetUserByPending;
