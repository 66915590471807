import { navigate } from 'gatsby';
import { message } from 'antd';
import firebase from "gatsby-plugin-firebase";
import { basePath } from '../../../common';

export const signOut = async () => {
  try {
    await firebase.auth().signOut();
    navigate(basePath());

  } catch (error) {
    console.log(error);
  }
};

export const onClickSignIn = async (email: string, password: string) => {

  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
    const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];
    if (hasuraClaim) {
      message.success('로그인 완료!');
      navigate(basePath());
    } else {
      await firebase.auth().signOut();
      message.error('잘못된 사용자 입니다.');
    }
  } catch (err) {
    message.error('잘못된 사용자 입니다.');
  }
}

export const onClickSignUp = async (email: string, password: string) => {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
    navigate(basePath());

  } catch (err) {
    console.log(err);
  }
}
