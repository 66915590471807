import React, { FC, useState } from 'react';
import { format } from 'date-fns'
import { Table, Breadcrumb, Layout, Tag, Input, Select, Divider } from 'antd';
import { Link } from "@reach/router";

import { stateStr, genderStr, basePath } from '../../../common';
import { useGetUsers } from '../repository/user_repository';
import Loading from '../../../components/loading';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const ListView: FC = () => {
  const [category, setCategory] = useState<string>('all');
  const { loading, items, refrash } = useGetUsers();

  const onSearch = (keyword: string) => {
    console.log('search')
    refrash({
      keyword: keyword,
      category: category,
    })
  }

  const onChange = (value: string) => {
    if (value == 'all') {
      refrash({
        keyword: '',
        category: 'all',
      })
    }
    setCategory(value);
  }

  const data = items.map((item) => {
    const tags = [item.state]
    if (item.is_delete) {
      tags.push('is_delete')
    } else if (item.is_block) {
      tags.push('is_block')
    } else if (item.is_private) {
      tags.push('is_private')
    }

    return {
      key: item.id,
      ...item,
      tags: tags,
    }
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <Link to={basePath(`/users/${id}`)}>{id}</Link>
      )
    },
    {
      title: '상태',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags: Array<string>) => (
        <>
          {tags.map(tag => {
            var stateObj = stateStr(tag)
            return <Tag color={stateObj.color} key={stateObj.value}>
              {stateObj.value}
            </Tag>
          })}
        </>
      ),
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      render: (gender: string) => (
        <span style={{ color: genderStr(gender).color }}>{genderStr(gender).value}</span>
      )
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '생성일',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (createdTime: Date) => (
        <span>{format(new Date(createdTime), 'yyyy-MM-dd HH:mm')}</span>
      )
    },
  ];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>사용자</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Input.Group compact>
          <Select value={category} onChange={onChange} style={{ width: 80 }} >
            <Option value="all">전체</Option>
            <Option value="id">ID</Option>
            <Option value="name">이름</Option>
            <Option value="nickname">닉네임</Option>
            <Option value="email">이메일</Option>
            <Option value="phone">전화번호</Option>
          </Select>
          <Search placeholder="검색" loading={loading} disabled={category == 'all'} onSearch={onSearch} style={{ width: 200 }} />
        </Input.Group>
        <Divider />
        <Table dataSource={data} columns={columns} loading={loading} />
      </div>
    </Content>
  );
}

export default ListView;
