import gql from 'graphql-tag';

export const GET_USERS = gql`
  query GetUsers($limit: Int, $offset: Int, $where: users_bool_exp = {}) {
    items: users(order_by: {id: desc}, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      email
      name
      phone
      gender
      birthday
      memo
      grade
      state
      is_private
      is_block
      is_delete
      invite_code
      updated_time
      created_time
      user_scores {
        profile_score
        gender_score
        private_score
        bonus_score
        verify_total_score
        total_score
        updated_time
        created_time
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($id: Int_comparison_exp) {
    items: users(where: {id: $id}, limit: 1) {
      id
      uid
      email
      name
      phone
      gender
      birthday
      memo
      grade
      state
      is_private
      is_block
      is_delete
      invite_code
      updated_time
      created_time
      gems(limit: 1, order_by: {created_time: desc}) {
        amount
      }
      verifies(order_by: {type: asc}) {
        id
        type
        symbol
        urls
        new_urls
        state
        message
        updated_time
      }
      profiles {
        nickname
        job
        region
        blood_type
        body_type
        height
        drinking
        education
        religion
        smoking
        hobbies
        characters
        ideal_types
        introduction
        interviews
      }
      medias(order_by: {index: asc}) {
        id
        index
        url
        type
        state
        new_url
        message
        updated_time
      }
      user_scores {
        profile_score
        gender_score
        private_score
        bonus_score
        verify_total_score
        total_score
        updated_time
        created_time
      }
    }
  }
`