import React from 'react';
import { Router } from '@reach/router';

import useCheckAuth from '../hooks/useCheckAuth';

// router
import BasicRoute from '../core/route/basicRoute';
import PrivateRoute from '../core/route/privateRoute';

// component
import Layout from '../components/layout';
import Loading from '../components/loading';

// screen
import Signin from '../features/signin/screen/signin_screen';
import UserList from '../features/user/screen/user_screen';
import UserDetail from '../features/user/screen/user_detail_screen';
import PendingList from '../features/pending/screen/pending_screen';
import RequestVerify from '../features/request_verify/screen/request_verify_screen';
import RequestMedia from '../features/request_media/screen/request_media_screen';
import Dashboard from '../features/dashboard';
import { basePath } from '../common';

const IndexPage: React.FC = () => {

  const { authState: { isLoading, isLoggedIn } } = useCheckAuth();
  if (isLoading) {
    return (
      <Loading />
    )
  } else {
    if (isLoggedIn) {
      return (<Layout>
        <Router basepath={basePath()}>
          <PrivateRoute default component={Dashboard} />
          <PrivateRoute path="/users" component={UserList} />
          <PrivateRoute path="/users/:userId" component={UserDetail} />
          <PrivateRoute path="/pending" component={PendingList} />
          <PrivateRoute path="/request_verify" component={RequestVerify} />
          <PrivateRoute path="/request_media" component={RequestMedia} />
        </Router>
      </Layout>
      )
    } else {
      return (
        <Router basepath={basePath()}>
          <BasicRoute default component={Signin} />
        </Router>
      );
    }
  }

  return (
    <Loading />
  )
};

export default IndexPage;
